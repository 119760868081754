import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { PARALLAX_STRENGTH } from "app/utils/constants";
import React from "react";
import { Parallax } from "react-parallax";
import aboutBg from "./assets/about-bg.png";
import gummyBearBg from "./assets/gummybear-bg.svg";
import { ReactComponent as GummyBearText } from "./assets/gummybear-text.svg";
import { ReactComponent as GummyBear } from "./assets/gummybear.svg";
import plankSvg1 from "./assets/tbm-asset-plank-01.svg";
import plankSvg2 from "./assets/tbm-asset-plank-02.svg";
import { ReactComponent as ChecklistIcon } from "./assets/tbm-checklist.svg";
import { ReactComponent as ZilSymbol } from "./assets/zil-symbol.svg";
import { BearCarousel } from "./components";

const BENEFITS = [
  "Be part of the inaugural Zilswap NFT marketplace collection!",
  "Bears are your bragging rights and VIP ticket to all things NFT on Zilswap!",
  "Only 10,000 ever to be minted, randomly generated in a fair manner!",
  "Rare bears sighted! Weren’t those extinct?!",
  "Own a piece of Zilliqa x Switcheo history. There won't be anything like this again!",
];

const About: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();

  return (
    <Parallax
      className={classes.root}
      bgImage={aboutBg}
      strength={PARALLAX_STRENGTH}
    >
      <section id="about">
        <Container maxWidth="md" className={classes.aboutContainer}>
          {/* The Bear Market */}
          <Box
            mb={4}
            display="flex"
            flexDirection="column"
            className={classes.welcomeBox}
          >
            <Typography variant="h1" className={classes.aboutHeader}>
              HONESTLY, A BEAR MARKET?!
            </Typography>

            <Typography className={classes.aboutText}>
              Well we aren't just a bear market. We are The Bear Market. We know
              a couple of fudders who have been releasing bears into the
              unknown, and because of you guys we now have a shelter full of
              lost and lonely bears. <br />
              <span className={classes.bodyTextMargin}>
                As much as we would love to care for all these unbearably cuddly
                bears, we simply can't keep up! Thus we've launched The Bear
                Market, in hope that every one of you will adopt one because
                these koala-ity bears deserve a loving home!
              </span>
            </Typography>

            <Typography className={classes.aboutSubheader}>
              ADOPTION FEE: 2,000 <ZilSymbol className={classes.zilSymbol} />
            </Typography>
          </Box>

          <BearCarousel />

          {/* Why adopt one*/}
          <Box
            mt={4}
            display="flex"
            flexDirection="column"
            className={classes.reasonsBox}
          >
            <Typography variant="h1" className={classes.aboutHeader}>
              WHY ADOPT ONE?
            </Typography>

            <List>
              {BENEFITS.map((benefit) => (
                <ListItem key={benefit}>
                  <ListItemIcon>
                    <ChecklistIcon className={classes.checklistIcon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span className={classes.boldText}>{benefit}</span>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Typography className={classes.luckyText} align="center">
            AND IF YOU’RE{" "}
            <span className={classes.orangeText}>{"$(!*#&^{@)!!@[**#!"}</span>{" "}
            LUCKY...
          </Typography>

          {/* Legendary gummy bear */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.gummyBearBox}
          >
            <Box className={classes.gummyBear}>
              <GummyBear className={classes.gummyBearSvg} />
            </Box>
            <Box className={classes.gummyBearText}>
              <GummyBearText className={classes.gummyBearTextSvg} />
            </Box>
          </Box>
        </Container>
      </section>
    </Parallax>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: "'M PLUS Rounded 1c', sans-serif",
    },
    "& .MuiListItemText-primary": {
      fontSize: "25px",
      lineHeight: "40px",
      color: "#FFFFFF",
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
        lineHeight: "30px",
      },
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& .MuiListItemIcon-root": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "40px",
      },
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  aboutContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  welcomeBox: {
    backgroundImage: `url(${plankSvg1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    padding: theme.spacing(6, 10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 4),
    },
  },
  reasonsBox: {
    backgroundImage: `url(${plankSvg2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    padding: theme.spacing(6, 10),
    // padding: theme.spacing(6, 14),
    [theme.breakpoints.down("xs")]: {
      // padding: theme.spacing(8, 8),
      padding: theme.spacing(3, 4),
    },
  },
  gummyBearBox: {
    backgroundImage: `url(${gummyBearBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPositionX: "center",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingBottom: theme.spacing(4),
      backgroundSize: "100% 70%",
      backgroundPositionY: "bottom",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0),
    },
  },
  gummyBear: {
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    height: "400px",
    width: "400px",
    animation: `$floatEffect 2000ms linear infinite`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-30px",
      maxHeight: "350px",
      maxWidth: "350px",
    },
  },
  gummyBearSvg: {
    height: "100%",
  },
  gummyBearText: {
    display: "flex",
    position: "relative",
    height: "400px",
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "250px",
      maxWidth: "250px",
    },
  },
  gummyBearTextSvg: {
    height: "100%",
  },
  aboutHeader: {
    fontFamily: '"Baloo Paaji 2", cursive!important',
    color: "#FCCC14",
    fontSize: "50px",
    lineHeight: "52px",
    textShadow: "0 4px 4px rgba(81, 21, 0, 25%)",
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      lineHeight: "35px",
    },
  },
  aboutText: {
    color: "#FFFFFF",
    fontSize: "25px",
    lineHeight: "40px",
    fontWeight: 900,
    textShadow: "0 4px 4px rgba(81, 21, 0, 25%)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  aboutSubheader: {
    fontFamily: '"Baloo Paaji 2", cursive!important',
    color: "#FFFFFF",
    fontSize: "35px",
    lineHeight: "50px",
    marginTop: theme.spacing(2.5),
    textShadow: "0 4px 4px rgba(81, 21, 0, 25%)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "45px",
    },
  },
  aboutDivider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  luckyText: {
    fontFamily: '"Baloo Paaji 2", cursive!important',
    fontSize: "45px",
    color: "#511500",
    lineHeight: "50px",
    wordBreak: "break-word",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      lineHeight: "45px",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
    },
  },
  orangeText: {
    color: "#FF5252",
  },
  boldText: {
    fontWeight: 900,
    textShadow: "0 4px 4px rgba(81, 21, 0, 25%)",
  },
  zilSymbol: {
    verticalAlign: "middle",
    marginBottom: "3px",
  },
  checklistIcon: {
    height: 36,
    width: 36,
    marginRight: theme.spacing(4),
    filter: "drop-shadow(0 4px 4px rgba(81, 21, 0, 25%))",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(2),
    },
  },
  bodyTextMargin: {
    display: "inline-block",
    marginTop: theme.spacing(2.5),
  },
  "@keyframes floatEffect": {
    from: { transform: "rotate(0deg) translateX(4px) rotate(0deg)" },
    to: { transform: "rotate(360deg) translateX(4px) rotate(-360deg)" },
  },
}));

export default About;
