import { Box, IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as DiscordIcon } from "./social-icons/discord.svg";
import { ReactComponent as TwitterIcon } from "./social-icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "./social-icons/instagram.svg";

const SocialLinkGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IconButton
        className={classes.iconButton}
        href="https://twitter.com/bearmarketnft"
        target="_blank"
        style={{ marginRight: "4px" }}
      >
        <TwitterIcon />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        href="https://www.instagram.com/thebearmarketnft"
        target="_blank"
        style={{ marginLeft: "4px", marginRight: "4px" }}
      >
        <InstagramIcon />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        href="http://discord.gg/ESVqQ3qtvk"
        target="_blank"
        style={{ marginLeft: "4px" }}
      >
        <DiscordIcon />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    "& a": {
      minWidth: 0,
      padding: theme.spacing(1),
      "& svg": {
        width: 24,
        height: 24,
        margin: 1,
        "& path": {
          transition: "fill .2s ease-in-out",
          fill: "#FFFFFF",
        },
        [theme.breakpoints.down("xs")]: {
          width: 20,
          height: 20,
        },
      },
    },
  },
  iconButton: {
    backgroundColor: "#511500",
    "&:hover": {
      backgroundColor: "#511500",
    },
  },
}));

export default SocialLinkGroup;
