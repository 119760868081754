
import { actions } from "app/store";
import { ChainInitAction } from "app/store/blockchain/actions";
import { WalletAction, WalletActionTypes } from "app/store/wallet/actions";
import { LocalStorageKeys, RPCEndpoints } from "app/utils/constants";
import { logger } from "core/utilities";
import { getConnectedZilPay } from "core/utilities/zilpay";
import { getConnectedBoltX } from "core/utilities/boltx";
import { ConnectedWallet, connectWalletBoltX, ConnectWalletResult, connectWalletZilPay, WalletConnectType } from "core/wallet";
import { eventChannel, EventChannel, channel, Channel } from "redux-saga";
import { call, fork, put, select, take, takeEvery } from "redux-saga/effects";
import { Zilswap, ObservedTx, TxReceipt, TxStatus, } from "zilswap-sdk";
import { getBlockchain, getWallet, getToken } from '../selectors';
import { TBMConnector } from "app/tbm";
import { NftMetadata } from "app/store/types";


const getProviderOrKeyFromWallet = (wallet: ConnectedWallet | null) => {
  if (!wallet) return null;

  switch (wallet.type) {
    case WalletConnectType.PrivateKey:
      return wallet.addressInfo.privateKey
    case WalletConnectType.Zeeves:
    case WalletConnectType.ZilPay:
      return wallet.provider;
    case WalletConnectType.BoltX:
      return wallet.provider;
    case WalletConnectType.Moonlet:
      throw new Error("moonlet support under development");
    default:
      throw new Error("unknown wallet connector");
  }
}

const zilPayObserver = (zilPay: any) => {
  return eventChannel<ConnectedWallet>(emitter => {
    const accountObserver = zilPay.wallet.observableAccount();
    const networkObserver = zilPay.wallet.observableNetwork();

    accountObserver.subscribe(async (account: any) => {
      logger(`Zilpay account changed to: ${account.bech32}`)
      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult?.wallet) {
        emitter(walletResult.wallet)
      }
    });

    networkObserver.subscribe(async (net: string) => {
      logger(`Zilpay network changed to: ${net}`)
      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult?.wallet) {
        emitter(walletResult.wallet)
      }
    });

    logger('registered zilpay observer')

    return () => {
      logger('deregistered zilpay observer')
      accountObserver.unsubscribe()
      networkObserver.unsubscribe()
    }
  })
}

function* reconnectWallet() {
  try {
    let walletResult: ConnectWalletResult;

    const connectedBoltx = localStorage.getItem(LocalStorageKeys.BoltxConnected);
    if (connectedBoltx === "true") {
      const boltx = (yield call(getConnectedBoltX)) as unknown as any
      walletResult = (yield call(connectWalletBoltX, boltx)) as ConnectWalletResult;
    } else {
      const zilPay = (yield call(getConnectedZilPay)) as unknown as any;
      walletResult = (yield call(connectWalletZilPay, zilPay)) as ConnectWalletResult;
    }

    if (walletResult.wallet) {
      const { wallet } = walletResult;
      const { network } = wallet;

      yield put(actions.Blockchain.initialize({ wallet, network }))
    }
  } catch (error) {
    console.warn('Wallet reconnect failed. Error:')
    console.warn(error)
  }
}

function* fetchSalesActive() {
  try {
    const isActive: boolean = yield TBMConnector.checkSalesActive();
    yield put(actions.Token.updateSales(isActive));
  } catch (error) {
    console.error("update sales error")
    console.error(error)
    yield put(actions.Token.updateSales(false));
  }
}

function* fetchSupply() {
  try {
    const [totalSupply, currentSupply, giveawaySupply, reservedSupply]: Array<number> = yield TBMConnector.getSupply();
    yield put(actions.Token.updateSupply({ totalSupply, currentSupply, giveawaySupply, reservedSupply }));
  } catch (error) {
    console.error("update sales error")
    console.error(error)
    yield put(actions.Token.updateSupply({ totalSupply: 0, currentSupply: 0, giveawaySupply: 0, reservedSupply: 0 }));
  }
}

function* initialize(action: ChainInitAction, txChannel: Channel<TxObservedPayload>) {
  let sdk: Zilswap | null = null;
  try {
    yield put(actions.Layout.addBackgroundLoading('initChain', 'INIT_CHAIN'))
    yield put(actions.Wallet.update({ wallet: null }))
    yield put(actions.Token.clearTokens())

    const { network, wallet } = action.payload
    const providerOrKey = getProviderOrKeyFromWallet(wallet)
    const { network: prevNetwork } = getBlockchain(yield select());

    sdk = new Zilswap(network, providerOrKey ?? undefined, { rpcEndpoint: RPCEndpoints[network] });
    logger('zilswap sdk initialized')

    yield call([sdk, sdk.initialize], txObserver(txChannel))
    TBMConnector.setSDK(sdk)

    yield put(actions.Blockchain.updateSaleState())
    yield put(actions.Wallet.update({ wallet }))
    if (network !== prevNetwork) yield put(actions.Blockchain.setNetwork(network))

    yield put(actions.Blockchain.initialized());
  } catch (err) {
    console.error(err)
    sdk = yield call(teardown, sdk)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('INIT_CHAIN'))
    yield put(actions.Token.updateImage());
  }
  return sdk
}

function* updateToken() {
  try {
    yield put(actions.Layout.addBackgroundLoading('updateToken', 'UPDATE_TOKEN'))
    const { wallet } = getWallet(yield select());
    if (wallet) {
      const tokenIds: string[] = yield TBMConnector.getOwnedTokens(wallet);
      logger("update token id", tokenIds);
      yield put(actions.Token.setTokens(tokenIds));
    }
    const { tokens } = getToken(yield select());
    const tokenData: NftMetadata[] = yield TBMConnector.getOwnedTokensImage(Object.keys(tokens));
    logger("update token images", tokenData);
    yield put(actions.Token.updateToken(tokenData));
  } catch (error) {
    console.error("update token error")
    console.error(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('UPDATE_TOKEN'))
  }
}

function* teardown(sdk: Zilswap | null) {
  if (sdk) {
    yield call([sdk, sdk.teardown])
    TBMConnector.setSDK(null)
  }
  return null
}

type TxObservedPayload = { tx: ObservedTx, status: TxStatus, receipt?: TxReceipt }
const txObserver = (channel: Channel<TxObservedPayload>) => {
  return (tx: ObservedTx, status: TxStatus, receipt?: TxReceipt) => {
    logger('tx observed', tx)
    channel.put({ tx, status, receipt })
  }
}

function* txObserved(payload: TxObservedPayload) {
  logger('tx observed action', payload)
  const { tx, status, receipt } = payload
  const { currentMinting } = getToken(yield select());
  if (currentMinting?.hash === tx.hash && receipt && status === "confirmed") {
    const tokenIds: string[] = [];
    receipt.event_logs.forEach((event) => {
      if (event._eventname === "MintSuccess") {
        tokenIds.push(
          event.params.find((param) => param.vname === "token_id")?.value
        );
      }
    });
    yield put(actions.Token.setTokens(tokenIds));
    yield put(actions.Token.updateCurrentMinting({ ...tx, status, receipt }));
    yield put({ type: actions.Token.TokenActionTypes.UPDATE_TOKEN_IMAGES });
  }
}

function* watchInitialize() {
  const txChannel: Channel<TxObservedPayload> = channel()
  let sdk: Zilswap | null = null;
  try {
    yield takeEvery(txChannel, txObserved)
    while (true) {
      const action: ChainInitAction = yield take(actions.Blockchain.BlockchainActionTypes.CHAIN_INIT)
      sdk = yield call(teardown, sdk)
      sdk = yield call(initialize, action, txChannel)
    }
  } finally {
  }
}

function* watchZilPay() {
  let channel
  while (true) {
    try {
      const action: WalletAction = yield take(WalletActionTypes.WALLET_UPDATE)
      if (action.payload.wallet?.type === WalletConnectType.ZilPay) {
        logger('starting to watch zilpay')
        const zilPay = (yield call(getConnectedZilPay)) as unknown as any;
        channel = (yield call(zilPayObserver, zilPay)) as EventChannel<ConnectedWallet>;
        break
      }
    } catch (e) {
      console.warn('Watch Zilpay failed, will automatically retry on reconnect. Error:')
      console.warn(e)
    }
  }

  try {
    while (true) {
      const newWallet = (yield take(channel)) as ConnectedWallet
      const { wallet: oldWallet } = getWallet(yield select())
      if (oldWallet?.type !== WalletConnectType.ZilPay) continue
      if (newWallet.addressInfo.bech32 === oldWallet?.addressInfo.bech32 &&
        newWallet.network === oldWallet.network) continue
      yield put(actions.Blockchain.initialize({ wallet: newWallet, network: newWallet.network }))
    }
  } finally {
    logger("channel closed")
    channel.close()
  }
}

function* watchTokens() {
  try {
    while (true) {
      yield take(actions.Token.TokenActionTypes.UPDATE_TOKEN_IMAGES);
      yield call(updateToken);
    }
  } catch (err) {
    console.warn("Watch token error, Error:")
    console.warn(err)
  }
}

function* watchSaleState() {
  let loadStateId: string | undefined;
  while (true) {
    try {
      if (getToken(yield select()).totalSupply === 0) {
        loadStateId = 'RELOAD_SALE_STATE';
      } else {
        loadStateId = undefined;
      }

      if (loadStateId)
        yield put(actions.Layout.addBackgroundLoading('initChain', loadStateId))

      yield take(actions.Blockchain.BlockchainActionTypes.UPDATE_SALE_STATE);
      yield call(fetchSalesActive);
      yield call(fetchSupply);
    } catch (err) {
      console.warn("Watch token error, Error:")
      console.warn(err)
    } finally {
      if (loadStateId)
        yield put(actions.Layout.removeBackgroundLoading(loadStateId))
    }
  }
}

function* watchReconnect() {
  try {
    while (true) {
      yield take(actions.Wallet.WalletActionTypes.WALLET_RECONNECT);
      yield call(reconnectWallet)
    }
  } catch (err) {
    console.warn("Watch reconnection error, Error:")
    console.warn(err);
  }
}

function* init() {
  try {

    yield put(actions.Layout.addBackgroundLoading('initChain', 'INIT_WITHOUT_WALLET'))
    const network = getBlockchain(yield select()).network

    const sdk = new Zilswap(network, undefined, { rpcEndpoint: RPCEndpoints[network] });
    logger('zilswap sdk initialized')

    yield call([sdk, sdk.initialize])
    TBMConnector.setSDK(sdk)

    yield put(actions.Blockchain.ready());
    yield put(actions.Blockchain.updateSaleState())
  } catch (err) {
    console.warn("Watch token error, Error:")
    console.warn(err)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading('INIT_WITHOUT_WALLET'))
  }
}

export default function* blockchainSaga() {
  logger("init blockchain saga");
  yield fork(watchInitialize);
  yield fork(watchZilPay);
  yield fork(watchSaleState);
  yield fork(watchTokens);
  yield fork(watchReconnect);
  yield init();
}
