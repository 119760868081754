import { combineReducers } from "redux";

import blockchain from "./blockchain/reducer";
import token from "./token/reducer";
import wallet from "./wallet/reducer";
import layout from "./layout/reducer";

export default combineReducers({
  blockchain, wallet, layout, token,
});
