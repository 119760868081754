import {
  Box,
  Card,
  CardContent,
  CardProps,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { NftMetadata } from "app/store/types";
import { useRedux } from "app/utils";
import { bnOrZero } from "app/utils/strings/strings";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import NftImage from "../NftImage";
import { Background, Base, Body, Eyes, Head, Mouth } from "./assets";

export interface Props extends CardProps {
  metadata: NftMetadata;
  showAttributes?: boolean;
  showAll?: boolean;
}

export interface AttributeMap {
  [key: string]: string;
}

const ATTRIBUTE_ICONS: AttributeMap = {
  Background,
  Base,
  Body,
  Eyes,
  Head,
  Mouth,
};

const NftCard: React.FC<Props> = (props: Props) => {
  const { showAll, className, metadata, showAttributes, ...rest } = props;
  const isRevealed = useRedux(
    (state) => !!state.token.revealedTokens[metadata.id]
  );
  const [attributesOverride, setAttributesOverride] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (showAttributes) return;
    if (!isRevealed) return;

    setTimeout(() => setAttributesOverride(true), 3000);
  }, [isRevealed, showAttributes]);

  const rarityLabel = (rarity?: number) => {
    if (typeof rarity !== "number") return "?";
    return bnOrZero(rarity).shiftedBy(2).toFixed(1);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <NftImage showAll={showAll} metadata={metadata} />
      <CardContent className={classes.cardContent}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography gutterBottom className={classes.tokenId}>
            #{metadata.id}
          </Typography>
        </Box>

        {(showAttributes || attributesOverride) &&
          metadata.attributes?.map((attribute) => (
            <Box key={attribute.trait_type} display="flex" alignItems="center">
              <img
                src={ATTRIBUTE_ICONS[attribute.trait_type]}
                alt="attribute icon"
                className={classes.attributeIcon}
              />
              <Typography className={classes.attributeText}>
                {attribute.trait_type}: {attribute.value}
              </Typography>
              <Typography className={classes.attributeRarity}>
                {rarityLabel(attribute.rarity)}%
              </Typography>
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "308px",
    boxShadow: "none",
    position: "relative",
  },
  tokenId: {
    color: "#511500",
    fontSize: "40px",
    lineHeight: "50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  cardContent: {
    marginLeft: "-16px",
    marginRight: "-16px",
  },
  attributeIcon: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: 24,
    width: 24,
    flexShrink: 0,
    flexGrow: 0,
    marginRight: theme.spacing(2),
  },
  attributeText: {
    color: "#511500",
    fontSize: "16px",
    lineHeight: "30px",
    flexGrow: 1,
  },
  attributeRarity: {
    color: "#511500",
    fontSize: "16px",
    lineHeight: "30px",
    minWidth: "30px",
    textAlign: "right",
  },
  link: {
    color: "#511500",
    cursor: "pointer",
  },
}));

export default NftCard;
