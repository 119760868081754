import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cls from "classnames";
import React, { useEffect, useState } from "react";
import { ReactComponent as TinyBearSVG } from "../../asset/tbm-icon-bear.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
  heroText: {
    color: "#FF5252",
    fontSize: "35px",
    lineHeight: "42px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  heroColor: {
    color: "#FF5252",
  },
  tinybear: {
    marginLeft: theme.spacing(1),
    alignSelf: "center",
  },
  mintButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 80,
    width: 250,
    borderRadius: "20px",
    backgroundColor: "#FF5252",
    "& .MuiTypography-root": {
      fontSize: "35px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
    },
  },
  bearSvg: {
    margin: "0 5px 0 5px",
  },
  largeBear: {
    height: "30px",
    width: "30px",
    margin: "0 5px 0 5px",
  },
}));

const STEP_DELAY = 200;
const STEP_SHADES = 3;
const STEP_COUNT = 6;

export interface CustomLoaderProps {
  large?: boolean;
}

const CustomLoader: React.FC<CustomLoaderProps> = (
  props: CustomLoaderProps
) => {
  const { large } = props;
  const classes = useStyles();
  const [indicatorIndex, setIndicatorIndex] = useState(~~(STEP_COUNT / 2));
  let timer: number | undefined;

  useEffect(() => {
    runLoader();
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  const runLoader = (num = indicatorIndex) => {
    if (timer) clearTimeout(timer);
    timer = (setTimeout(() => {
      if (num === STEP_COUNT - 1) num = 0;
      else num += 1;
      setIndicatorIndex(num);
      runLoader(num);
    }, STEP_DELAY) as unknown) as number;
  };

  const getOpacity = (index: number) => {
    if (index === indicatorIndex) return 1;
    const overflow = Math.abs(index - indicatorIndex);
    const overflowDiff = Math.max(0, overflow - STEP_SHADES);
    if (overflowDiff > 0)
      return 1 / STEP_SHADES * (overflowDiff);

    const diff = Math.min(STEP_SHADES, Math.abs(indicatorIndex - index));
    return 1 / STEP_SHADES * (STEP_SHADES - diff);
  };

  return (
    <Box mt={2} justifyContent="center" display="flex" flexDirection="row">
      {[...Array(STEP_COUNT)].map((arr, index) => (
        <TinyBearSVG
          key={index}
          opacity={getOpacity(index)}
          className={cls(classes.bearSvg, { [classes.largeBear]: !!large })}
        />
      ))}
    </Box>
  );
};

export default CustomLoader;
