import { LocalStorageKeys } from "../../utils/constants";
import { WalletActionTypes } from "./actions";
import { WalletState } from "./types";
import { Dayjs } from "dayjs";
import { WalletProvider } from "zilswap-sdk";
import { Network } from "zilswap-sdk/lib/constants";
import { WalletConnectType } from "core/wallet/ConnectedWallet";

export type WalletAccountInfo = {
  byte20: string;
  bech32: string;
  privateKey?: string;
};

export type ConnectedWallet = {
  provider?: WalletProvider;
  type: WalletConnectType;
  network: Network;

  timestamp: Dayjs;
  addressInfo: WalletAccountInfo;
};

const initial_state: WalletState = {
  wallet: null,
};

const logoutRemovedKeys: string[] = [
  LocalStorageKeys.ZilPayConnected,
  LocalStorageKeys.BoltxConnected,
];

const reducer = (state: WalletState = initial_state, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case WalletActionTypes.WALLET_UPDATE: {
      const wallet: ConnectedWallet | null = payload.wallet;

      logoutRemovedKeys.forEach(key => localStorage.removeItem(key));
      switch (wallet?.type) {
        case WalletConnectType.ZilPay:
          localStorage.setItem(LocalStorageKeys.ZilPayConnected, "true");
          break;
        case WalletConnectType.BoltX:
          localStorage.setItem(LocalStorageKeys.BoltxConnected, "true");
          break;
        default:
          break;
      }
      return { ...state, ...payload };
    }
    default:
      return state;
  };
}

export default reducer;
