import {
  Backdrop,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseRounded";
import cls from "classnames";
import React from "react";
import BasicSVG from "../Hero/basic-bear.svg";

export interface DialogModalProps extends DialogProps {
  header?: string;
}

const DialogModal: React.FC<DialogModalProps> = (props: DialogModalProps) => {
  const { children, className, header, onClose, ...rest } = props;
  const classes = useStyles();

  const onCloseButton = (e: any) => {
    if (typeof onClose === "function") onClose(e, "backdropClick");
  };

  return (
    <Dialog
      maxWidth={"md"}
      closeAfterTransition
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      PaperProps={{
        elevation: 0,
        className: classes.paperProps,
      }}
      {...rest}
      className={cls(classes.root, className)}
    >
      <Box className={classes.bearBG} />
      <Box className={classes.container}>
        {typeof onClose === "function" && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onCloseButton}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h3">{header}</Typography>
        </DialogTitle>
        {children}
      </Box>
    </Dialog>
  );
};


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FF5252",
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  },
  dialogTitle: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px 12px 0 0",
  },
  container: {
    border: "5px solid #FF5252",
    borderRadius: "20px",
    boxSizing: "border-box",
    background: "#FFFFFF",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  },
  paperProps: {
    borderRadius: "20px",
    background: "#FFFFFF",
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  bearBG: {
    backgroundImage: `url(${BasicSVG})`,
    backgroundRepeat: "no-repeat",
    height: "130px",
    width: "130px",
    backgroundSize: "100% 100%",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
    alignSelf: "center",
    bottom: "-70px",
    position: "relative",
    zIndex: 2000,
    [theme.breakpoints.down("xs")]: {
      height: "100px",
      width: "100px",
      bottom: "-50px",
    },
  },
}));

export default DialogModal;
