import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import cls from "classnames";
import { Link } from "react-router-dom";
import { SocialLinkGroup } from "app/components";
import { ReactComponent as Brand } from "app/components/TopBar/tbm-brand.svg";

const Footer: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" my={6}>
        <Button
          component={Link}
          to="/"
          className={classes.brandButton}
          disableRipple
        >
          <Brand className={classes.brand} />
        </Button>

        <Typography className={cls(classes.rights, classes.roundedMplus)}>
          All Rights Reserved 2021
        </Typography>

        <Link to="/terms" className={classes.termsLink}>
          <Typography className={cls(classes.terms, classes.roundedMplus)}>
            Terms and Conditions
          </Typography>
        </Link>

        <Box className={classes.followUsBox}>
          <Typography className={classes.followUs}>Follow us</Typography>
          <Box display="flex">
            <SocialLinkGroup />
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: "10px solid #FF5252",
    "& .MuiTypography-root": {
      color: "#511500",
    },
    background: "#FCCC14",
    flexShrink: 0,
  },
  brand: {
    height: "40px",
    width: "330px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "300px",
    },
  },
  rights: {
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  termsLink: {
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "underline!important",
      textDecorationColor: "#511500!important",
    },
  },
  terms: {
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  roundedMplus: {
    fontFamily: "'M PLUS Rounded 1c', sans-serif!important",
    fontWeight: 500,
  },
  followUsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "8px",
  },
  followUs: {
    fontFamily: '"Baloo Paaji 2", cursive',
    fontSize: "16px",
    lineHeight: "34px",
  },
  brandButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    textTransform: "none",
    border: "none!important",
    padding: 0,
  },
}));

export default Footer;
