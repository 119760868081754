import { Box, makeStyles, Theme } from "@material-ui/core";
import { actions } from "app/store";
import { useRedux } from "app/utils";
import { SaleTime } from "app/utils/constants";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FlipUnitContainer } from "./components";

const MS_SECOND = 1000;
const MS_MINUTE = MS_SECOND * 60;
const MS_HOUR = MS_MINUTE * 60;
const MS_DAY = MS_HOUR * 24;

const FlipClock: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaleActive = useRedux((state) => state.token.saleActive);
  const network = useRedux((state) => state.blockchain.network);
  const [countdown, setCountdown] = useState<number[]>([0, 0, 0, 0]);
  const [queryStarted, setQueryStarted] = useState<number | undefined>(undefined);
  const [hideCountdown, setHideCountdown] = useState<boolean>(true);

  useEffect(() => {
    const query = () => {
      if (isSaleActive) return;
      dispatch(actions.Blockchain.updateSaleState());
      setQueryStarted(setTimeout(query, 5000) as unknown as number);
    };
    if (isSaleActive || queryStarted) return;

    if (!countdown[0] && !countdown[1] && !countdown[2] && !countdown[3]) {
      query();
    }

    // eslint-disable-next-line
  }, [countdown]);

  useEffect(() => {
    if (isSaleActive && queryStarted)
      clearTimeout(queryStarted);
  }, [queryStarted, isSaleActive]);

  useEffect(() => {
    const updateTime = () => {
      // get new date
      const startDate = SaleTime[network]?.start ?? SaleTime.MainNet.start;
      const endDate = SaleTime[network]?.end ?? SaleTime.MainNet.end;
      const revealDate = SaleTime[network]?.reveal ?? SaleTime.MainNet.reveal;
      const time = new Date().getTime();
      let timeLeft = 0;

      if (time <= startDate) {
        // before sale starts
        timeLeft = startDate - time;
      } else if (isSaleActive && time <= endDate) {
        // time left until sale ends
        timeLeft = endDate - time;
      } else if (time < revealDate) {
        // time left until reveal
        timeLeft = revealDate - time;
      }

      if (timeLeft <= 0) {
        if (!hideCountdown)
          setHideCountdown(true);
        return null;
      }

      if (hideCountdown)
        setHideCountdown(false);

      // set time units
      const updateDays = ~~(timeLeft / MS_DAY);
      const updateHours = ~~((timeLeft / MS_HOUR) % 24);
      const updateMinutes = ~~((timeLeft / MS_MINUTE) % 60);
      const updateSeconds = ~~((timeLeft / MS_SECOND) % 60);
      setCountdown([
        updateDays,
        updateHours,
        updateMinutes,
        updateSeconds
      ]);
    };

    const interval = setInterval(() => updateTime(), 1000);

    return () => clearInterval(interval);
  }, [isSaleActive, network, hideCountdown]);

  const moreThanADay = countdown[0] > 0;
  if (hideCountdown) return null;

  return (
    <Box
      className={classes.root}
      style={{ maxWidth: !moreThanADay ? "280px" : "" }}
    >
      {moreThanADay && <FlipUnitContainer>{countdown[0]}</FlipUnitContainer>}
      <FlipUnitContainer>{countdown[1]}</FlipUnitContainer>
      <FlipUnitContainer>{countdown[2]}</FlipUnitContainer>
      <FlipUnitContainer>{countdown[3]}</FlipUnitContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignSelf: "center",
    },
  },
}));

export default FlipClock;
