import { AppBar, Button, ButtonGroup, Grid, IconButton, makeStyles, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MenuRounded";
import cls from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { actions } from "../../store";
import { useRouter } from "../../utils";
import ConnectWalletButton from "../ConnectWalletButton";
import { ReactComponent as Brand } from "./tbm-brand.svg";

const TopBar: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const location = router.location;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const onToggleDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer());
  };

  return (
    <AppBar
      className={classes.root}
      color="transparent"
      elevation={0}
      position="relative"
    >
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between">
          {/* Nav links */}
          <Grid item md={2} className={classes.gridItem}>
            <ButtonGroup variant="text" disableRipple>
              <Button
                component={Link}
                to="/"
                className={classes.brandButton}
                disableRipple
              >
                <Brand className={classes.brand} />
              </Button>
              <Button
                component={HashLink}
                smooth
                to="/#about"
                className={classes.navLinkButton}
                disableRipple
              >
                <Typography className={classes.navLink}>About</Typography>
              </Button>
              <Button
                component={HashLink}
                smooth
                to="/#faq"
                className={classes.navLinkButton}
                disableRipple
              >
                <Typography className={classes.navLink}>FAQ</Typography>
              </Button>
              <Button
                component={Link}
                to="/gallery"
                className={classes.navLinkButton}
                disableRipple
              >
                <Typography
                  className={cls(
                    classes.navLink,
                    location.pathname === "/gallery" && classes.selectedMenu
                  )}
                >
                  My Bears
                </Typography>
              </Button>
              <Button
                component={Link}
                to="/universe"
                className={classes.navLinkButton}
                disableRipple
              >
                <Typography
                  className={cls(
                    classes.navLink,
                    location.pathname === "/universe" && classes.selectedMenu
                  )}
                >
                  Bear Universe
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid>

          {/* Connect Wallet Button */}
          <Grid className={classes.navButtonGrid}>
            {!isSm && (
              <ConnectWalletButton className={classes.connectWalletButton} />
            )}
          </Grid>

          <Grid className={classes.navDrawerGrid}>
            <IconButton onClick={onToggleDrawer}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 0,
    "& .MuiTypography-root": {
      fontFamily: "'M PLUS Rounded 1c', sans-serif!important",
    },
    "& .MuiToolbar-regular": {
      minHeight: "100px",
      [theme.breakpoints.up("sm")]: {
        minHeight: "120px",
      },
    },
    "& .MuiToolbar-gutters": {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "48px",
        paddingRight: "48px",
      },
    },
  },
  brand: {
    height: "40px",
    width: "330px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "200px",
    },
  },
  toolbar: {
    "& .MuiToolbar-gutters": {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "32px",
        paddingRight: "32px",
      },
    },
  },
  brandButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    textTransform: "none",
    border: "none!important",
  },
  navLinkButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    textTransform: "none",
    marginLeft: theme.spacing(4),
    border: "none!important",
    textDecoration: "none!important",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navLink: {
    fontSize: "25px",
    color: "#511500",
    fontWeight: 500,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  navButtonGrid: {
    alignSelf: "center",
  },
  navDrawerGrid: {
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  menuIcon: {
    color: "#511500",
    fontSize: "1.8rem",
  },
  selectedMenu: {
    color: "#FF5252",
    fontWeight: 900,
  },
  gridItem: {
    flexBasis: "inherit",
    maxWidth: "none",
  },
  brandLogo: {
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
    fontSize: "inherit",
    height: "38px",
    width: "38px",
  },
  connectWalletButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default TopBar;
