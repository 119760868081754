import { Box, Drawer, DrawerProps, IconButton, List, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import { SocialLinkGroup } from "app/components";
import { actions } from "app/store";
import cls from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavDrawer: React.FC<DrawerProps> = (props: DrawerProps) => {
  const { children, className, onClose, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };

  return (
    <Drawer
      anchor="right"
      PaperProps={{ className: classes.paper }}
      onClose={onClose}
      {...rest}
      className={cls(classes.root, className)}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon className={classes.chevronIcon} />
        </IconButton>
      </div>
      <Box className={classes.content}>
        <List>
          <ListItem
            button
            component={HashLink}
            smooth
            to="/#about"
            className={classes.listItem}
            onClick={handleDrawerClose}
          >
            <ListItemText primary="About" />
          </ListItem>
          <ListItem
            button
            component={HashLink}
            smooth
            to="/#faq"
            className={classes.listItem}
            onClick={handleDrawerClose}
          >
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/gallery"
            className={classes.listItem}
            onClick={handleDrawerClose}
          >
            <ListItemText primary="My Bears" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/universe"
            className={classes.listItem}
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Bear Universe" />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.footer}>
        <Typography>Follow us</Typography>
        <Box display="flex">
          <SocialLinkGroup />
        </Box>
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiListItem-gutters": {
      paddingRight: "32px",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "unset",
    minWidth: 250,
    backgroundColor: "#FCCC14",
  },
  content: {
    flex: 1,
    overflowY: "auto",
    textAlign: "right",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "32px",
    "& .MuiTypography-root": {
      color: "#511500",
      fontFamily: '"Baloo Paaji 2", cursive',
      fontSize: "20px",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: "22px",
    },
    paddingBottom: "0px!important",
    justifyContent: "flex-start",
  },
  chevronIcon: {
    color: "#511500",
    fontSize: "2rem",
  },
  listItem: {
    textAlign: "right",
    textDecoration: "none !important",
    "& .MuiTypography-root": {
      color: "#511500",
      fontFamily: '"Baloo Paaji 2", cursive',
      fontSize: "20px",
    },
  },
}));

export default NavDrawer;
