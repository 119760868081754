import { Box, BoxProps, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContent, SnackbarKey, SnackbarProvider, SnackbarMessage } from "notistack";
import React, { forwardRef } from "react";
import CloseIcon from "@material-ui/icons/CloseOutlined";

interface Props extends BoxProps {
  message: SnackbarMessage,
  providerRef: React.MutableRefObject<SnackbarProvider>,
  snackKey: SnackbarKey,
}

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "16px",
    color: "#ffffff",
  },
  snackbar: {
    background: "#FF5252",
    border: "3px solid #FF5252",
    color: "#ffffff",
    padding: "16px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
}));

const SnackbarContentItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, message, providerRef, snackKey, className, ...rest } = props;
  const classes = useStyles();

  const onClickDismiss = () => {
    return () => {
      if (providerRef.current) {
        providerRef.current.closeSnackbar(snackKey);
      }
    };
  };

  return (
    <SnackbarContent {...rest} ref={ref} className={classes.snackbar}>
      <Typography>&nbsp;&nbsp;{message}&nbsp;</Typography>
      <Box flexGrow={1} />
      <IconButton size="small" onClick={onClickDismiss()}>
        <CloseIcon className={classes.icon} />
      </IconButton>
    </SnackbarContent>
  );
});

export default SnackbarContentItem;
