import { SimpleMap } from "app/utils";
import { Dayjs } from "dayjs";
import { ObservedTx } from "zilswap-sdk";
import { NftMetadata, SupplyProps, CurrentMintInfo } from "./types";

export const TokenActionTypes = {
  SET_TOKENS: "SET_TOKENS",
  UPDATE_TOKENS: "UPDATE_TOKENS",
  UPDATE_TOKEN_IMAGES: "UPDATE_TOKEN_IMAGES",
  REVEAL_TOKENS: "REVEAL_TOKENS",
  CLEAR_TOKENS: "CLEAR_TOKENS",
  UPDATE_SUPPLY: "UPDATE_SUPPLY",
  UPDATE_SALE: "UPDATE_SALE",
  UPDATE_CURRENT_MINTING: "UPDATE_CURRENT_MINTING",
}

export const updateToken = (payload: NftMetadata[]) => ({
  type: TokenActionTypes.UPDATE_TOKENS,
  payload
})

export const setTokens = (payload: string[]) => ({
  type: TokenActionTypes.SET_TOKENS,
  payload
})

export const clearTokens = () => ({
  type: TokenActionTypes.CLEAR_TOKENS
})

export const updateImage = () => ({
  type: TokenActionTypes.UPDATE_TOKEN_IMAGES
})

export const revealTokens = (payload: SimpleMap<Dayjs>) => ({
  type: TokenActionTypes.REVEAL_TOKENS,
  payload
})

export const updateSupply = (payload: SupplyProps) => ({
  type: TokenActionTypes.UPDATE_SUPPLY,
  payload
})

export const updateSales = (payload: boolean) => ({
  type: TokenActionTypes.UPDATE_SALE,
  payload
})

export const updateCurrentMinting = (payload: (ObservedTx & CurrentMintInfo) | null) => ({
  type: TokenActionTypes.UPDATE_CURRENT_MINTING,
  payload
})
