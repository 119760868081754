import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import RemoveIcon from "@material-ui/icons/RemoveRounded";
import { PARALLAX_STRENGTH } from "app/utils/constants";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Parallax } from "react-parallax";
import Scroll from "react-scroll";
import faqBg from "./faq-bg.png";
import smallPlankSvg from "./tbm-asset-plank-small.svg";

const ScrollLink = Scroll.Link;

const FAQ: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange = (question: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? question : false);
  };

  return (
    <Parallax
      className={classes.root}
      bgImage={faqBg}
      strength={PARALLAX_STRENGTH}
    >
      {/* Banner and button */}
      <Box className={classes.adoptBanner}>
        <Typography className={classes.adoptBannerText} align="center">
          So what are you waiting for?
        </Typography>
      </Box>

      <Box mt={-6} display="flex" justifyContent="center">
        <ScrollLink to="hero" spy={true} smooth={true} duration={500}>
          <Button className={classes.adoptButton}>
            <Typography className={classes.adoptButtonText}>
              ADOPT NOW
            </Typography>
          </Button>
        </ScrollLink>
      </Box>

      <Container id="faq" maxWidth="md" className={classes.faqContainer}>
        <Typography align="center" className={classes.faqHeader}>
          FAQs
        </Typography>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question1"}
          onChange={handleAccordionChange("question1")}
        >
          <AccordionSummary
            expandIcon={expanded === "question1" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              How can I adopt a bear?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              All you need is a supported Zilliqa wallet
              {" "}
              (
              <Link href="https://zilpay.io/" target="_blank">ZilPay</Link>
              {" "}
              or
              {" "}
              <Link href="https://chrome.google.com/webstore/detail/bolt-x/aodkkagnadcbobfpggfnjeongemjbjca" target="_blank">BoltX</Link>
              )
              {" "}
              and adopt with ZILs! Bears will
              revealed in your gallery at the end of the sale!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question2"}
          onChange={handleAccordionChange("question2")}
        >
          <AccordionSummary
            expandIcon={expanded === "question2" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              Where does my bear go after I adopt it?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              If you are koalified, it'll appear in the address, or the
              connected wallet you used to pay the adoption fee (a real
              beargain).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question3"}
          onChange={handleAccordionChange("question3")}
        >
          <AccordionSummary
            expandIcon={expanded === "question3" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>Tokenomics..?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              A total of 10,000 randomly generated from over 1 million
              combinations. 200 bears will be pre-minted and reserved for
              giveaways and marketing activities.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question4"}
          onChange={handleAccordionChange("question4")}
        >
          <AccordionSummary
            expandIcon={expanded === "question4" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              What can I do with my bear?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              Bear owners are given unlimited, worldwide license to use, copy
              and display their purchased bear for personal use. Read more at
              the full <Link component={RouterLink} to="/terms">Terms and Conditions</Link>.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question6"}
          onChange={handleAccordionChange("question6")}
        >
          <AccordionSummary
            expandIcon={expanded === "question6" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              I want to meet other Pawrents!
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              You can sleuth on other members of the bear gang on our{" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="http://discord.gg/ESVqQ3qtvk"
                className={classes.link}
              >
                Discord
              </Link>
              {" "}
              and
              {" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/bearmarketnft"
                className={classes.link}
              >
                Twitter
              </Link>{" "}
              !
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === "question7"}
          onChange={handleAccordionChange("question7")}
        >
          <AccordionSummary
            expandIcon={expanded === "question7" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              Anything else I should know?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              As with any pet, bears are a commitment. Be sure to read the full
              {" "}
              <Link component={RouterLink} to="/terms">Terms and Conditions</Link>
              {" "}
              and think before you adopt!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === "question8"}
          onChange={handleAccordionChange("question8")}
        >
          <AccordionSummary
            expandIcon={expanded === "question8" ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography className={classes.question}>
              Where and when can I view my bears?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answer}>
              Your (unrevealed) cuddly critters will be listed under the My Bears page immediately after minting.
              To ensure full randomisation, bear holders will be able to reveal their
              minted bears the following week (target date: 23 September 2021 or earlier).
              Read more on our randomnization methodology {" "}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://docs.zilswap.io/nft-marketplace/the-bear-market/attribute-generation"
                className={classes.link}
              >
                here.
              </Link>{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>

      </Container>
    </Parallax>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    "& .MuiAccordion-root:before": {
      height: 0,
    },
    height: "100%",
    width: "100%",
  },
  faqContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(6),
    },
  },
  adoptBanner: {
    backgroundColor: "#FF5252",
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4),
  },
  adoptBannerText: {
    color: "#FFFFFF",
    fontSize: "50px",
    lineHeight: "60px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
      lineHeight: "40px",
    },
  },
  adoptButton: {
    backgroundImage: `url(${smallPlankSvg})`,
    backgroundColor: "#FF5252",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    borderRadius: "30px",
    border: "20px solid #FF5252",
    height: 130,
    width: 350,
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  adoptButtonText: {
    color: "#FFFFFF",
    fontSize: "35px",
    lineHeight: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "45px",
    },
  },
  faqHeader: {
    color: "#FFFFFF",
    fontSize: "100px",
    lineHeight: "120px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      fontSize: "50px",
      lineHeight: "50px",
    },
  },
  accordion: {
    boxShadow: "none",
    borderRadius: "30px!important",
    backgroundColor: "rgba(91, 100, 227, 0.5)",
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      color: "rgba(222, 255, 255, 0.5)",
    },
    "& .MuiAccordionSummary-root": {
      padding: theme.spacing(2.5, 5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2.5, 4),
      },
    },
    "& .MuiAccordionSummary-expandIcon": {
      transition: "none",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "inherit",
    },
    "& .MuiAccordionDetails-root": {
      padding: theme.spacing(0, 5, 2.5),
      display: "inherit",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 4, 2.5),
      },
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    marginBottom: theme.spacing(3),
  },
  question: {
    color: "#FFFFFF",
    fontSize: "35px",
    lineHeight: "50px",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "35px",
    },
  },
  answer: {
    color: "#FFFFFF",
    fontSize: "23px",
    lineHeight: "30px",
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  link: {
    color: "#FF5252",
  },
}));

export default FAQ;
