import { Box, Button, CircularProgress, Container, makeStyles, OutlinedInput, Theme, Typography } from "@material-ui/core";
import { NftCard, TopBar } from "app/components";
import { ReactComponent as BasicBearSVG } from "app/components/Hero/basic-bear.svg";
import { CustomLoader } from "app/components/Hero/components/MintDialog/components";
import { BlockchainState, NftMetadata, RootState } from "app/store/types";
import { TBMConnector } from "app/tbm";
import { useAsyncTask, useTaskSubscriber } from "app/utils";
import { LoadingKeys } from "app/utils/constants";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import heroBackgroundNarrow from "./hero-bg-narrow.png";

const BearUniverse: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const [runLoadSingle, isSingleLoading] = useAsyncTask("loadSingle");
  const [searchNumber, setSearchNumber] = useState<number>(0);
  const [input, setInput] = useState<string>("");
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const [singledisplay, setSingleDisplay] = useState<NftMetadata | null>(null)
  const blockchainState = useSelector<RootState, BlockchainState>(state => state.blockchain);

  const runSearch = (ev: any) => {
    if (searchNumber > 0) loadSingle(searchNumber)
  }

  const loadSingle = (tokenId?: number) => {
    runLoadSingle(async () => {
      setSingleDisplay(null);
      const singleTokenList = await TBMConnector.getOwnedTokensImage([tokenId + ""]);
      setSingleDisplay(singleTokenList[0]);
    })
  }

  const onInputBlur = () => {
    if (!searchNumber) setInput("");
    else setInput(searchNumber.toString());
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setInput(search)
    let number = parseInt(search);
    if (isNaN(number) || !isFinite(number) || number > 10000 || number < 1)
      number = 0;
    setSearchNumber(number);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <TopBar />
        <header className={classes.header}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.headerBox}
            >
              <BasicBearSVG className={classes.basicBear} />
            </Box>
          </Container>
        </header>
      </Box>

      <section id="gallery" className={classes.bearUniverse}>
        {blockchainState.ready && (
          <Container maxWidth="lg">
            <Box mb={3} display="flex" flexDirection="column" alignItems="center">
              <OutlinedInput
                className={classes.inputBox}
                placeholder={"Token ID"}
                value={input}
                type="number"
                inputProps={{ min: "1", max: "10000", style: { textAlign: "center" } }}
                onChange={onInputChange}
                onBlur={onInputBlur}
              />
              <Button
                className={classes.searchButton}
                disableFocusRipple
                onClick={runSearch}
              >
                {isLoading ? (
                  <CircularProgress size={18} className={classes.progress} />
                ) : (
                  <Typography variant="h1">SEARCH</Typography>
                )}
              </Button>
            </Box>
            <Box display="flex" className={classes.searchContainer} justifyContent="center" alignItems="center">
              {(singledisplay && !isSingleLoading) && (
                <NftCard showAttributes={true} showAll={true} metadata={singledisplay} />
              )}
              {isSingleLoading && (
                <CustomLoader />
              )}
            </Box>
          </Container>
        )}
      </section>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
    },
  },
  wrapper: {
    backgroundImage: `url(${heroBackgroundNarrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
    backgroundPositionX: "center",
    backgroundSize: "100% 100%",
  },
  header: {
    borderBottom: "10px solid #FF5252",
  },
  headerBox: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  progress: {
    color: "#FFFFFF",
  },
  basicBear: {
    height: "150px",
    width: "200px",
    marginBottom: "-1px",
  },
  inputBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: "5px solid #FF5252",
    borderRadius: "20px",
    height: 80,
    width: 250,
    color: "#FF5252",
    backgroundColor: "#FFFFFF",
    "& input": {
      padding: "17.5px 14px",
      fontSize: "40px",
      height: "0.5rem",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    "&.Mui-focused": {
      caretColor: "#FF5252",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: 53,
      width: 200,
      "& input": {
        padding: "10px 7px",
        fontSize: "35px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  bearUniverse: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      minHeight: "300px",
    },
    flexDirection: "column",
    alignItems: "center",
  },
  pagination: {
    bottom: 0,
    marginBottom: theme.spacing(4),
    justifyContent: "center"
  },
  searchButton: {
    marginBottom: theme.spacing(1),
    height: 70,
    width: 250,
    borderRadius: "20px",
    backgroundColor: "#FF5252",
    "& .MuiTypography-root": {
      fontSize: "35px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "rgba(255, 82, 82, 0.7)",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: 53,
      width: 200,
      "& .MuiTypography-root": {
        fontSize: "20px",
      },
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  searchContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  divider: {
    flex: 1,
    backgroundColor: "#FF5252",
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  myBearsTitle: {
    position: "relative",
    background: "#fff",
    color: "#FF5252",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "45px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default BearUniverse;
