import { makeStyles, Theme } from '@material-ui/core';
import GoogleAnalytics from 'app/components/GoogleAnalytics';
import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { About, FAQ, Gallery, Hero, Terms, BearUniverse } from "./app/components";
import { MainLayout } from "./app/layout";
import { startSagas } from "./app/saga";
import store from "./app/store";
import { actions } from "app/store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const App: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    startSagas();
    if (!store?.getState().wallet.wallet) {
      store.dispatch(actions.Wallet.reconnectWallet());
    }
  }, []);


  return (
    <Provider store={store}>
      <GoogleAnalytics />
      <Router>
        <MainLayout className={classes.root}>
          {/* routes here */}
          <Switch>
            <Route exact path="/">
              <Hero />
              <About />
              <FAQ />
            </Route>
            <Route exact path="/gallery">
              <Gallery />
            </Route>
            <Route exact path="/universe">
              <BearUniverse />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Redirect to="/" />
          </Switch>
        </MainLayout>
      </Router>
    </Provider>
  );
};

export default App;
