import { NftMetadata, TokenState } from "./types";
import { TokenActionTypes } from "./actions";
import { LocalStorageKeys } from "app/utils/constants";
import dayjs, { Dayjs } from "dayjs";
import { SimpleMap } from "app/utils";

const savedReveal = localStorage.getItem(LocalStorageKeys.RevealedToken);
let storedRevealedToken: SimpleMap<Dayjs> = {}

try {
  if (savedReveal) {
    const storedData = JSON.parse(savedReveal);
    for (const key in storedData) {
      storedRevealedToken[key] = dayjs(storedData[key])
    }
  }
} catch (error) {
  console.error(error);
}

const initial_state: TokenState = {
  currentMinting: null,
  tokens: {},
  revealedTokens: storedRevealedToken ?? {},
  totalSupply: 0,
  currentSupply: 0,
  giveawaySupply: 0,
  reservedSupply: 0,
  saleActive: false,
}

const reducer = (state: TokenState = initial_state, action: any): TokenState => {
  const { payload } = action;

  switch (action.type) {
    case TokenActionTypes.SET_TOKENS:
      let newTokens: SimpleMap<NftMetadata> = {};
      payload.forEach((id: string) => {
        newTokens[id] = { id };
      });
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...newTokens,
        }
      }
    case TokenActionTypes.UPDATE_TOKENS:
      let updateTokens: SimpleMap<NftMetadata> = {};
      payload.forEach((token: NftMetadata) => {
        updateTokens[token.id] = token;
      });
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...updateTokens,
        }
      }
    case TokenActionTypes.CLEAR_TOKENS:
      return {
        ...state,
        tokens: {},
      }
    case TokenActionTypes.REVEAL_TOKENS:
      const newRevealedTokens = {
        ...state.revealedTokens,
        ...payload,
      }

      localStorage.setItem(LocalStorageKeys.RevealedToken, JSON.stringify(newRevealedTokens))
      return {
        ...state,
        revealedTokens: newRevealedTokens
      }
    case TokenActionTypes.UPDATE_SUPPLY:
      return {
        ...state,
        ...payload
      }
    case TokenActionTypes.UPDATE_SALE:
      return {
        ...state,
        saleActive: payload
      }
    case TokenActionTypes.UPDATE_CURRENT_MINTING:
      let newMinting = state.currentMinting;
      if (!payload) newMinting = null;
      else newMinting = { ...newMinting, ...payload };
      return {
        ...state,
        currentMinting: newMinting
      }
    default:
      return state;
  }
}

export default reducer
