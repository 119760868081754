import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { TopBar } from "app/components";
import { ReactComponent as BasicBearSVG } from "app/components/Hero/basic-bear.svg";
import React from "react";
import heroBackgroundNarrow from "app/components/Gallery/hero-bg-narrow.png";

const Terms: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <TopBar />
        <header className={classes.header}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.headerBox}
            >
              <BasicBearSVG className={classes.basicBear} />
              <Typography className={classes.headerText}>
                Please read beary carefully!
              </Typography>
            </Box>
          </Container>
        </header>
      </Box>

      <section id="terms">
        <Container maxWidth="lg" className={classes.container}>
          {/* Terms and Conditions */}
          <Box mb={3}>
            <Typography className={classes.heading}>
              Terms and Conditions
            </Typography>
            <Typography className={classes.body}>
              The Bear Market is a collection of digital assets represented by
              non-fungible tokens (NFTs) on the Zilliqa blockchain. Ownership of
              these digital assets (hereafter "Bears") is governed solely by the
              smart contracts running on the Zilliqa network.
              <br />
              <br />
              This website and all connected services are provided as is, and
              with no warranties of any kind. By using this website, you accept
              sole responsibility for any transactions involving the Bears, and
              you agree wholly to these Terms and Conditions ("Terms").
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              1 | Transferability
            </Typography>
            <Typography className={classes.body}>
              Non-transferrable until launch of i) ZilSwap NFT platform, or ii)
              OpenSea support via cross-chain bridge or otherwise, or iii) 180
              days, whichever is earliest.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              2 | Resale Price
            </Typography>
            <Typography className={classes.body}>
              No guarantee of secondary market price or rarity.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              3 | Preview Art
            </Typography>
            <Typography className={classes.body}>
              Actual NFT minted is random, preview art is indicative only - user
              should only purchase if they like the collection as a theme rather
              than any particular NFT.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              4 | No Guarantee of Future Development
            </Typography>
            <Typography className={classes.body}>
              No guarantee on any future work or development, including
              cross-chain support.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              5 | Ownership and Commercial Use
            </Typography>
            <Typography className={classes.body}>
              Ownership of the Bears is governed solely by the Zilliqa
              blockchain and the smart contracts deployed thereon. As a
              decentralized blockchain, there is no ability to undo, reverse, or
              restore any transactions made. You accept sole responsibility for
              any transactions made which involve the Bears.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              6 | Personal Use
            </Typography>
            <Typography className={classes.body}>
              As long as you continue to comply with these Terms, Switcheo Labs
              grants you a worldwide, royalty-free license to use, copy, and
              display the purchased Bear(s), along with any extensions or
              derivatives that you choose to create or use, solely for the
              following purposes: (i) for your own personal, non-commercial use;
              (ii) as part of a marketplace that permits the purchase and sale
              of your Bear / NFT, provided that the marketplace
              cryptographically verifies each Bear owner’s rights to display
              their Bear to ensure that only the actual owner can display the
              Bear; or (iii) as part of a third party website or application
              that permits the involvement or usage of your Bear, provided that
              the website/application cryptographically verifies each Bear
              owner’s rights to display their Bear to prevent non-owners from
              displaying illicit copies of the Bear, and provided that the Bear
              is no longer being used on the site once the owner of the Bear
              leaves the website/application.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              7 | Commercial Use
            </Typography>
            <Typography className={classes.body}>
              As long as you continue to comply with these Terms, Switcheo Labs
              grants you an unlimited, worldwide license to use, copy, and
              display the purchased Bear for the purpose of creating derivative
              works based upon the Bear (“Commercial Use”). Examples of such
              Commercial Use would be producing printed copies of the Bear on
              merchandise products (T-Shirts etc.). Nothing in this Section will
              be deemed to restrict you from (i) owning or operating a
              marketplace that permits the use and sale of Bears generally,
              provided that the marketplace cryptographically verifies each Bear
              owner’s rights to display their Bear to prevent non-owners from
              displaying illicit copies of the Bear; (ii) owning or operating a
              third-party website or application that permits the inclusion,
              involvement, or participation of Bears generally, provided that
              the third party website or application cryptographically verifies
              each Bear owner’s rights to display their Bear to prevent
              non-owners from displaying illicit copies of the Bear, and
              provided that the Bear is no longer being used on the site once
              the owner of the Bear leaves; or (iii) earning revenue from any of
              the commercial uses mentioned herein.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography className={classes.subheading}>
              8 | Severability
            </Typography>
            <Typography className={classes.body}>
              In the event any provision or part of these Terms is found to be
              invalid or unenforceable, only that particular provision or part
              so found, and not the entire Terms, will be inoperative.
            </Typography>
          </Box>
        </Container>
      </section>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
    },
  },
  wrapper: {
    backgroundImage: `url(${heroBackgroundNarrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
    backgroundPositionX: "center",
    backgroundSize: "100% 100%",
  },
  header: {
    borderBottom: "10px solid #FF5252",
  },
  headerText: {
    color: "#511500",
    fontSize: "40px",
    lineHeight: "60px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "45px",
      marginBottom: theme.spacing(2),
    },
  },
  headerBox: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  basicBear: {
    height: "150px",
    width: "200px",
    marginBottom: "-1px",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  heading: {
    fontSize: "35px",
    lineHeight: "50px",
    color: "#FF5252",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "35px",
    },
  },
  subheading: {
    fontSize: "25px",
    lineHeight: "35px",
    color: "#FF5252",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  body: {
    fontFamily: "'M PLUS Rounded 1c', sans-serif!important",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "35px",
    color: "#511500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
}));

export default Terms;
