import dayjs from "dayjs";
import { LayoutActionTypes } from "./actions";
import { LayoutState } from "./types";

const initial_state: LayoutState = {
  loadingTasks: {},
  tasksRegistry: {},
  showNavDrawer: false,
  showWalletDialog: false,
};

const reducer = (state: LayoutState = initial_state, action: any) => {
  let loadingTask = null, taskName;
  switch (action.type) {
    case LayoutActionTypes.ADD_BACKGROUND_LOADING:
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
          [action.name]: {
            ...(state.loadingTasks[action.name] || {}),
            [action.uuid]: dayjs(),
          },
        },
        tasksRegistry: {
          ...state.tasksRegistry,
          [action.uuid]: action.name,
        },
      };
    case LayoutActionTypes.REMOVE_BACKGROUND_LOADING:
      taskName = state.tasksRegistry[action.uuid];
      if (!taskName)
        return state;
      loadingTask = state.loadingTasks[taskName];
      if (!loadingTask || !loadingTask[action.uuid])
        return state;

      delete loadingTask[action.uuid];
      if (!Object.keys(loadingTask).length)
        delete state.loadingTasks[taskName];
      delete state.tasksRegistry[action.uuid];
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      };
    case LayoutActionTypes.TOGGLE_SHOW_NAV_DRAWER:
      return {
        ...state,
        showNavDrawer: !action.override ? !state.showNavDrawer : action.override === "open",
    };
    case LayoutActionTypes.TOGGLE_SHOW_WALLET:
      return {
        ...state,
        showWalletDialog: !action.override ? !state.showWalletDialog : action.override === "open",
      };
    default:
      return state;
  };
}

export default reducer;
