import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { animated, useSpring, config } from "react-spring";
import { Box, makeStyles, Theme } from "@material-ui/core";
import clockBg from "./clock-bg.svg";

type Props = {
  children: number;
};

const usePrevious = (currentValue: number) => {
  const previousValue = React.useRef(0);
  useEffect(() => {
    previousValue.current = currentValue;
  }, [currentValue]);
  return previousValue.current;
};

const FlipUnitContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const [currentNumber, setCurrentNumber] = React.useState(0);
  const previousNumber = usePrevious(currentNumber);

  useEffect(() => {
    setCurrentNumber(children);
  }, [children]);

  const frontCardAnimation = useSpring({
    from: { transform: "rotateX(0deg)" },
    to: { transform: "rotateX(-180deg)" },
    delay: 0,
    config: config.slow,
    reset: true,
  });

  const backCardAnimation = useSpring({
    from: { transform: "rotateX(180deg)" },
    to: { transform: "rotateX(0deg)" },
    delay: 0,
    config: config.slow,
    reset: true,
  });

  const displayNumber =
    currentNumber >= 10 ? currentNumber : `0${currentNumber}`;
  const displayPrevNumber =
    previousNumber >= 10 ? previousNumber : `0${previousNumber}`;

  return (
    <Container>
      {currentNumber === previousNumber && (
        <Fragment>
          <Box>
            <StaticCardTop>
              <span>{displayNumber}</span>
            </StaticCardTop>
            <StaticCardBottom>
              <Box className={classes.gradient} />
              <span>{displayPrevNumber}</span>
            </StaticCardBottom>
          </Box>
        </Fragment>
      )}

      {currentNumber !== previousNumber && (
        <Fragment>
          <StaticCardTop>
            <span>{displayNumber}</span>
          </StaticCardTop>
          <StaticCardBottom>
            <Box className={classes.gradient} />
            <span>{displayPrevNumber}</span>
          </StaticCardBottom>

          <AnimatedCardFront style={frontCardAnimation}>
            <span>{displayPrevNumber}</span>
          </AnimatedCardFront>
          <AnimatedCardBack style={backCardAnimation}>
            <Box className={classes.gradient} />
            <span>{displayNumber}</span>
          </AnimatedCardBack>
        </Fragment>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  gradient: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    height: 20,
    width: "inherit",
    background:
      "linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)",
    mixBlendMode: "multiply",
    opacity: 0.6,
  },
}));

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 80px;
  grid-template-rows: 40px 40px;
  grid-template-areas: "top" "bottom";
  width: 80px;
  height: 80px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: #ff5252;
  border-radius: 20px;
  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
    grid-template-columns: 60px;
    grid-template-rows: 30px 30px;
  }
`;

const StaticCardTop = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-area: top;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ff5252;
  background-image: url(${clockBg});
  background-size: cover;
  background-repeat: no-repeat;
  span {
    font-family: "Baloo Paaji 2", cursive !important;
    font-size: 2.5em;
    transform: translateY(20%);
    color: #ffffff;
  }
  @media (max-width: 600px) {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    span {
      transform: translateY(10%);
      font-size: 2em;
    }
  }
`;

const StaticCardBottom = styled("div")`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-area: bottom;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ff5252;
  background-image: url(${clockBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
  span {
    font-family: "Baloo Paaji 2", cursive !important;
    font-size: 2.5em;
    transform: translateY(-80%);
    color: #ffffff;
  }
  @media (max-width: 600px) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    span {
      transform: translateY(-90%);
      font-size: 2em;
    }
  }
`;

const AnimatedCardFront = styled(animated.div)`
  display: flex;
  position: absolute;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  transform-origin: center bottom;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateX(0deg);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ff5252;
  background-image: url(${clockBg});
  background-size: cover;
  background-repeat: no-repeat;
  span {
    font-family: "Baloo Paaji 2", cursive !important;
    font-size: 2.5em;
    transform: translateY(20%);
    color: #ffffff;
  }
  @media (max-width: 600px) {
    transform: translateY(10%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    span {
      font-size: 2em;
    }
    height: 30px;
  }
`;

const AnimatedCardBack = styled(animated.div)`
  display: flex;
  position: absolute;
  justify-content: center;
  top: 40px;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  transform: rotateX(180deg);
  transform-origin: center top;
  backface-visibility: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ff5252;
  background-image: url(${clockBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
  span {
    font-family: "Baloo Paaji 2", cursive !important;
    font-size: 2.5em;
    transform: translateY(-80%);
    color: #ffffff;
  }
  @media (max-width: 600px) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    span {
      transform: translateY(-90%);
      font-size: 2em;
    }
    top: 30px;
    height: 30px;
  }
`;

export default FlipUnitContainer;
