import { Box, makeStyles, Theme } from "@material-ui/core";
import { CAROUSEL_AUTOPLAY } from "app/utils/constants";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import bear1 from "./assets/bear-1.png";
import bear2 from "./assets/bear-2.png";
import bear3 from "./assets/bear-3.png";
import bear4 from "./assets/bear-4.png";
import bear5 from "./assets/bear-5.png";
import bear6 from "./assets/bear-6.png";

const BearCarousel: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props: any
) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} {...rest}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={CAROUSEL_AUTOPLAY}
        showDots={false}
        arrows={false}
        draggable={false}
      >
        {/* <img
          src="https://thebearmarket.s3.ap-southeast-1.amazonaws.com/assets/644a21d4ccdb2b5d045c7c8873dd1e182e9425119318ef466aa0c3ced0949e45.png"
          className={classes.bear}
          alt="bear1"
        />
        <img
          src="https://thebearmarket.s3.ap-southeast-1.amazonaws.com/assets/a4b54b7efa5f4c456d2056bc05810d6dd77d48fff2bd1ae9888416d43f669dae.png"
          className={classes.bear}
          alt="bear2"
        />
        <img
          src="https://thebearmarket.s3.ap-southeast-1.amazonaws.com/assets/86a396a3c16e10c01fc8ce640521506a9d2b582fd704d9eac3acd421247a92d7.png"
          className={classes.bear}
          alt="bear3"
        />
        <img
          src="https://thebearmarket.s3.ap-southeast-1.amazonaws.com/assets/9862e77cf67ae1f67521403a70eb5a08dd8eb7525bb406f4093cabcfc4ac00c9.png"
          className={classes.bear}
          alt="bear4"
        />
        <img
          src="https://thebearmarket.s3.ap-southeast-1.amazonaws.com/assets/d34173d29b3ed3515877235a701c44cc14a6d87345f3b63c5b494caea733ba51.png"
          className={classes.bear}
          alt="bear4"
        /> */}
        <img src={bear1} className={classes.bear} alt="bear1" />
        <img src={bear2} className={classes.bear} alt="bear2" />
        <img src={bear3} className={classes.bear} alt="bear3" />
        <img src={bear4} className={classes.bear} alt="bear4" />
        <img src={bear5} className={classes.bear} alt="bear5" />
        <img src={bear6} className={classes.bear} alt="bear6" />
      </Carousel>
    </Box>
  );
};


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  bear: {
    width: "calc(100% - 16px)",
    height: "calc(100% - 16px)",
    margin: theme.spacing(1),
  },
}));

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 480 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 480, min: 320 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 320, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

export default BearCarousel;
