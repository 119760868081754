import { Zilswap } from "zilswap-sdk";
import { contractInitToMap } from "zilswap-sdk/lib/utils";
import { ConnectedWallet, } from "core/wallet/ConnectedWallet";
import { NftMetadata } from "app/store/types";
import { ContractsBech32 } from "app/utils/constants";
import { fromBech32Address } from "@zilliqa-js/zilliqa";
import { bnOrZero } from "app/utils/strings/strings";

let zilswap: Zilswap | null = null

export class TBMConnector {
  static setSDK = (sdk: Zilswap | null) => {
    zilswap = sdk
  }

  static getSDK = (): Zilswap => {
    if (!zilswap) throw new Error('not initialized');

    return zilswap
  }

  static getCurrentBlock = () => {
    if (!zilswap) throw new Error('not initialized');
    return zilswap.getCurrentBlock()
  }

  static getOwnedTokens = async (wallet: ConnectedWallet): Promise<string | unknown[]> => {
    if (!zilswap) throw new Error('not initialized');
    if (!wallet) throw new Error('invalid wallet');

    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Nft);
    const result = await nftContract.getSubState("token_owners");

    const ownedTokenIds = Object.entries(result.token_owners).filter(
      ([tokenId, owner]) => owner === wallet.addressInfo.byte20.toLowerCase()
    );

    return ownedTokenIds.map((entry) => entry[0])
  }

  static getOwnedTokensImage = async (tokenIds: string[]) => {
    if (!zilswap) throw new Error('not initialized');

    const nftContract = zilswap.zilliqa.contracts.atBech32(ContractsBech32[zilswap.network].Nft);
    const result = await nftContract.getSubState("token_uris");
    const tokenList: NftMetadata[] = [];

    for (const tokenId of tokenIds) {
      const response = await fetch(result.token_uris[tokenId] +  "?cache-buster");
      const metadata = await response.json();
      tokenList.push(metadata as NftMetadata);
    }

    return tokenList;
  }

  static getSupply = async () => {
    if (!zilswap) throw new Error('not initialized');
    const address = fromBech32Address(ContractsBech32[zilswap.network].Nft);
    const nftContract = zilswap.getContract(address);
    const init = await nftContract.getInit();
    const mappedInit = contractInitToMap(init);
    const totalSupply = mappedInit["max_supply"];

    const totalState = await nftContract.getSubState("total_supply");
    const currentSupply = bnOrZero(totalState.total_supply).toNumber();
    const reservedState = await nftContract.getSubState("total_reserved");
    const giveawaySupply = bnOrZero(reservedState.total_reserved).toNumber();
    const reservedSupply = bnOrZero(mappedInit.reserved_supply).toNumber();
    return [totalSupply, currentSupply, giveawaySupply, reservedSupply];
  }

  static checkSalesActive = async () => {
    if (!zilswap) throw new Error('not initialized');

    const minterAddress = ContractsBech32[zilswap.network].CommunityMinter;
    // minter not deployed
    if (minterAddress === "")
      return false;

    const address = fromBech32Address(minterAddress);
    const contract = zilswap.getContract(address);
    const status = await contract.getSubState("sale_active");

    return status.sale_active.constructor === "True";
  }
}
