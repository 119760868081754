import { OpenCloseState } from "./types";

export const LayoutActionTypes = {
  ADD_BACKGROUND_LOADING: "ADD_BACKGROUND_LOADING",
  REMOVE_BACKGROUND_LOADING: "REMOVE_BACKGROUND_LOADING",
  TOGGLE_SHOW_NAV_DRAWER: "TOGGLE_SHOW_NAV_DRAWER",
  TOGGLE_SHOW_WALLET: "TOGGLE_SHOW_WALLET",
};

export function addBackgroundLoading(name: string, uuid: string) {
  return {
    type: LayoutActionTypes.ADD_BACKGROUND_LOADING,
    name, uuid,
  };
};
export function removeBackgroundLoading(uuid: string) {
  return {
    type: LayoutActionTypes.REMOVE_BACKGROUND_LOADING,
    uuid,
  };
};
export function toggleShowNavDrawer(override?: OpenCloseState) {
  return {
    type: LayoutActionTypes.TOGGLE_SHOW_NAV_DRAWER,
    override,
  }
}
export function toggleShowWallet(override?: OpenCloseState) {
  return {
    type: LayoutActionTypes.TOGGLE_SHOW_WALLET,
    override,
  }
};
