import {
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogModal } from "app/components";
import { CustomLoader } from "app/components/Hero/components/MintDialog/components";
import { actions } from "app/store";
import { ConnectWalletResult, RootState } from "app/store/types";
import { useAsyncTask, useRedux, useTaskSubscriber } from "app/utils";
import { LoadingKeys } from "app/utils/constants";
import cls from "classnames";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectWalletBoltX, connectWalletZilPay } from "../../../core/wallet";

const MintDialog: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wallet = useRedux((state) => state.wallet.wallet);
  const showWalletDialog = useSelector<RootState, boolean>(
    (state) => state.layout.showWalletDialog
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading] = useTaskSubscriber(
    ...LoadingKeys.connectWallet,
    "initChain"
  );

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [runConnectZilPay, connectingZilPay] = useAsyncTask<void>(
    "connectWalletZilPay",
    (error) => {
      enqueueSnackbar(`Error connecting to ZilPay: ${error?.message ?? "Unknown"}`);
    }
  );
  const [runConnectBoltX, connectingBoltX] = useAsyncTask<void>(
    "connectWalletBoltX",
    (error) => {
      enqueueSnackbar(`Error connecting to Bolt X: ${error?.message ?? "Unknown"}`);
    }
  );

  const handleOnClose = () => {
    dispatch(actions.Layout.toggleShowWallet("close"));
  };

  const handleConnectZilPay = () => {
    connectZilPay();
  };

  const handleConnectBoltX = () => {
    connectBoltX();
  };

  const connectZilPay = () => {
    runConnectZilPay(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined") {
        throw new Error("ZilPay extension not installed");
      }

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect)
        throw new Error("ZilPay could not be connected to.");

      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult.error) throw walletResult.error;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Layout.toggleShowWallet("close"));
        return;
      }
    });
  };

  const connectBoltX = () => {
    runConnectBoltX(async () => {
      if (!!wallet) return;

      let attempt = 0;
      let boltX = undefined;
      let connectError = undefined;
      while (attempt < 3) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 1000));

          boltX = (window as any).boltX;
          if (typeof boltX === "undefined") {
            throw new Error("BoltX extension not installed");
          }

          const result = await boltX.connectAll();
          if (result !== boltX.isConnect) {
            throw new Error("BoltX could not be connected to.");
          }

          if (!boltX?.zilliqa?.wallet?.defaultAccount) {
            throw new Error("No default account on BoltX.");
          }

          if (boltX) break;
        } catch (error) {
          console.error(error);
          connectError = error;
          attempt++;
        }
      }

      if (!boltX) {
        if (connectError) throw connectError;
        return;
      }

      const walletResult: ConnectWalletResult = await connectWalletBoltX(boltX);
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Layout.toggleShowWallet("close"));
        return;
      }
    });
  };

  const connectingWalletText = () => {
    if (connectingZilPay) return "ZilPay Wallet...";
    if (connectingBoltX) return "Bolt X Wallet...";
  };

  return (
    <DialogModal open={showWalletDialog} onClose={handleOnClose}>
      <Box className={classes.boxPadding}>
        <Typography variant="h1" className={classes.titleText}>
          {isLoading ? "Connecting" : "Connect Wallet"}
          <br />
          {connectingWalletText()}
        </Typography>

        {isLoading ? (
          <Box mt={8} mb={8}>
            <CustomLoader large={!isXs} />
          </Box>
        ) : (
          <Box alignItems="center" display="flex" flexDirection="column">
            <Button
              className={cls(classes.button, classes.zilPayBottomMargin)}
              onClick={handleConnectZilPay}
              disableFocusRipple
            >
              <Typography className={classes.buttonText}>ZilPay</Typography>
            </Button>
            <Button
              className={cls(classes.button, classes.boltXButtonMargin)}
              onClick={handleConnectBoltX}
              disableFocusRipple
            >
              <Typography className={classes.buttonText}>Bolt X</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </DialogModal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  titleText: {
    color: "#FF5252",
    fontSize: "35px",
    lineHeight: "40px",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
      lineHeight: "35px",
    },
  },
  boxPadding: {
    padding: theme.spacing(4, 12),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 4),
    },
  },
  tinybear: {
    "& #bear": {
      fill: "#D39367",
    },
    marginLeft: theme.spacing(1),
    alignSelf: "center",
    height: "30px",
    width: "30px",
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: 70,
    width: "100%",
    borderRadius: "20px",
    backgroundColor: "#FF5252",
    textDecoration: "none!important",
    "& .MuiTypography-root": {
      fontSize: "35px",
      lineHeight: "50px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: "25px!important",
    lineHeight: "44px",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px!important",
      lineHeight: "32px",
    },
  },
  zilPayBottomMargin: {
    marginBottom: 0,
  },
  boltXButtonMargin: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  customLoader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default MintDialog;
