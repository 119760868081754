import { makeStyles, Theme } from "@material-ui/core";
import { Footer, NavDrawer, ScrollReset, WalletDialog } from "app/components";
import { useRedux } from "app/utils";
import React, { Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../store";
import { SnackbarProvider } from "notistack";
import { SnackbarContentItem } from "./components";

const MainLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props: any
) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const showNavDrawer = useRedux((state) => state.layout.showNavDrawer);
  const ref = useRef() as React.MutableRefObject<SnackbarProvider>;

  const onCloseDrawer = () => {
    dispatch(actions.Layout.toggleShowNavDrawer("close"));
  };

  return (
    <Fragment>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ref={ref}
        content={(key, message) => (
          <SnackbarContentItem
            snackKey={key}
            message={message}
            providerRef={ref}
          />
        )}
        maxSnack={3}
      >
        <ScrollReset />
        <main className={classes.root} {...rest}>
          {children}
          <Footer />
        </main>
        <WalletDialog />
      </SnackbarProvider>
      <NavDrawer open={showNavDrawer} onClose={onCloseDrawer} />
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "space-between",
  },
}));

export default MainLayout;
