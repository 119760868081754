import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { actions } from "app/store";
import { NftMetadata } from "app/store/types";
import { useAsyncTask, useRedux } from "app/utils";
import {
  CommonRevealSvgUrl,
  LegendaryRevealSvgUrl,
  PlaceholderSvgUrl,
  RareRevealSvgUrl
} from "assets";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Network } from "zilswap-sdk/lib/constants";

const REVEAL_ANIM_LENGTH = 5;

export interface Props extends BoxProps {
  disableLoad?: boolean;
  metadata: NftMetadata;
  showAll?: boolean;
}

const NftImage: React.FC<Props> = (props: Props) => {
  const { className, showAll, metadata, disableLoad, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const network = useRedux(state => state.blockchain.network);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [reveal, revealing] = useAsyncTask(`revealToken${metadata.id}`);
  const isRevealed = useRedux((state) => !!metadata.revealedAt || !!state.token.revealedTokens[metadata.id] || showAll);
  const [runNotifyReveal] = useAsyncTask("notifyReveal");

  const revealSvg = useMemo(() => {
    const baseAttr = metadata.attributes?.find(
      (attr) => attr.trait_type === "Base"
    )?.value;
    if (!baseAttr) return CommonRevealSvgUrl;

    if (["Brown", "Polar", "Koala", "Panda", "Unknown"].includes(baseAttr))
      return CommonRevealSvgUrl;
    if (["Zombie", "Alien"].includes(baseAttr)) return RareRevealSvgUrl;

    return LegendaryRevealSvgUrl;
  }, [metadata.attributes]);

  useEffect(() => {
    if (!metadata?.image || disableLoad) return;
    if (imageSrc === metadata.image) return;

    const img = new Image();
    img.onload = () => {
      setImageSrc(metadata.image!);
    };
    img.src = metadata.image;
  }, [metadata.image, disableLoad, imageSrc]);

  const onReveal = () => {
    if (!imageSrc || isRevealed || revealing) return;
    if (showAll) return;

    runNotifyReveal(async () => {
      let prefix = "http://localhost:8181";
      switch (network) {
        case Network.MainNet: {
          prefix = "https://api.thebear.market";
          break;
        }
        case Network.TestNet: {
          prefix = "https://test-api.thebear.market";
          break;
        }
      }
      await fetch(`${prefix}/metadata/${metadata.id}`, { method: "POST" });
    });

    reveal(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(
        actions.Token.revealTokens({
          [metadata.id]: dayjs(),
        })
      );

      await new Promise((resolve) =>
        setTimeout(resolve, (REVEAL_ANIM_LENGTH - 1) * 1000)
      );
    });
  };

  return (
    <Box
      {...rest}
      onClick={onReveal}
      className={clsx(classes.root, className, {
        [classes.revealed]: isRevealed,
        [classes.unclickable]: !imageSrc,
      })}
    >
      {((!showAll && !isRevealed) || (showAll && !imageSrc)) && (
        <object
          type="image/svg+xml"
          className={clsx(classes.animatedSvg, classes.layer, "placeholder")}
          data={PlaceholderSvgUrl}
        >
          Bear Placeholder
        </object>
      )}
      {(imageSrc) && (
        <img
          alt={`TBM Bear #${metadata.id}`}
          src={imageSrc}
          className={clsx(classes.image, classes.layer, "image")}
        />
      )}
      {revealing && (
        <object
          type="image/svg+xml"
          className={clsx(classes.animatedSvg, classes.layer)}
          data={revealSvg}
        >
          Revealing Bear
        </object>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "308px",
    position: "relative",
    cursor: "pointer",
  },
  unclickable: {
    cursor: "unset",
  },
  placeholder: {
    opacity: 1,
    transition: `opacity ${REVEAL_ANIM_LENGTH}s ease-in-out`,
  },
  revealed: {
    cursor: "unset",
    "& .placeholder": {
      opacity: 0,
    },
    "& .image": {
      opacity: 1,
    },
  },
  image: {
    position: "relative",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "inherit",
    opacity: 0,
    transition: `opacity ${REVEAL_ANIM_LENGTH - 2}s ease-in-out`,
  },
  animatedSvg: {
    pointerEvents: "none",
    width: "100%",
  },
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
}));

export default NftImage;
