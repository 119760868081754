import createSagaMiddleware from "redux-saga";
import blockchainSaga from "./app/blockchainSaga";

const sagaMiddleware = createSagaMiddleware();

export function startSagas() {
  sagaMiddleware.run(blockchainSaga)
};

export default sagaMiddleware;
